import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 p-2 m-auto animate-fade-in-slow ">
      <div className="p-4 md:p-8">
        <img
          className="object-cover w-full h-full"
          src="https://ankarakartonpiyer.com.tr/backend/wp-content/uploads/2024/01/WhatsApp-Image-2022-10-29-at-14.01.15-4.jpeg"
          alt="Top Right"
        />
      </div>

      <div className="p-4 m-auto bg-neutral-50 shadow-lg rounded-lg space-y-2 ">
        <h1 className="text-center text-4xl font-bold text-gray-900">{t("about.title")}</h1>
        <p className='font-semibold text-gray-900 text-lg'>{t("about.content1")}</p>
        <p className="text-gray-900 text-lg text-start">{t("about.content2")}</p> 
      </div>

      <div className="p-4 m-auto bg-neutral-50 shadow-lg rounded-lg space-y-2 ">
        <p className=' font-semibold text-gray-900 text-lg'></p>
        <p className="text-gray-900 text-lg text-start">{t("about.content3")}</p>
      </div>

      <div className="p-4 md:p-8">
        <img
          className="object-cover w-full h-full"
          src="https://ankarakartonpiyer.com.tr/backend/wp-content/uploads/2024/01/e7d974b3-6213-4137-87f2-ae898960031f_1628-1536x1152-1.jpg"
          alt="Bottom Left"
        />
      </div>
    </div>
  );
};

export default About;
