import React from 'react';
import EmailForm from './EmailForm';
import { Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';


const Contact = () => {
  const { t } = useTranslation();
  // Function to open Google Maps with navigation to the destination
  const openGoogleMaps = () => {
    const mapsUrl = `https://maps.app.goo.gl/Cas6KzLFVrNPo71B7?g_st=iw`;
    window.open(mapsUrl, '_blank');
  };

  // Function to handle phone link click
  const handlePhoneClick = () => {
    window.location.href = 'tel:(+90312)3126536'; 
  };

  // Function to handle email link click
  const handleEmailClick = () => {
    window.location.href = 'mailto:b.buyukkapu@gmail.com';
  };
 
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
      {/* Top Left: Email Form */}
      <div className="col-span-1">
        <EmailForm />
      </div>

      {/* Top Right: Contact Information */}
      <div className="col-span-1 fgrid grid-rows-2 items-center space-y-8 mt-8 mx-auto px-2 ">
      <div className="text-start bg-white rounded p-4">
        <Typography variant="body1">
          <strong>{t("contact.address")}</strong> Fuat Börekçi Caddesi Rüzgarlı İş Merkezi 54/3-A,
           Ulus/Ankara, 
           06050 Altındağ/Ankara
        </Typography>
        <Typography variant="body1">
          <strong>{t("contact.phone")}</strong>{' '}
          <Link component="span" underline="hover" onClick={handlePhoneClick} color="primary">
            (0312) 312 65 36
          </Link>
        </Typography>
        <Typography variant="body1">
          <strong>{t("contact.email")}</strong>{' '}
          <Link component="span" underline="hover" onClick={handleEmailClick} color="primary">
            b.buyukkapu@gmail.com
          </Link>
        </Typography>
      </div>
      <div className="col-span-1  lg:col-span-1">
        {/* Image with an onClick event to trigger navigation */}
        <img
          src="https://ankarakartonpiyer.com.tr/backend/wp-content/uploads/2024/01/Ekran-Resmi-2024-01-12-23.37.38.png"
          alt="Click to navigate on Google Maps"
          onClick={openGoogleMaps}
          style={{ cursor: 'pointer', width: '100%' }}
        />
      </div>
    </div>

      {/* Bottom: Map Image */}
      
    </div>
  );
};

export default Contact;
