import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SliderModal from './SliderModal';

const ProductCard = ({ key, title, text, imageUrl, price, targetLink, images, sale_price,hepsiburadaLink,n11Link,trendyolLink, videoLink}) => {
  const { t } = useTranslation();
  const [sliderOpen, setSliderOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
console.log(videoLink)
  const handleSliderOpen = () => {
    setSliderOpen(!sliderOpen);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleWhatsAppClick = () => {
    let phoneNumber = '+903123126536'; // Replace with the phone number you want to open in WhatsApp
    let message = 'Hello'; // Default message

    // You can customize the message based on the productId
    if (title) {
      message = `Merhaba, ${title} kodlu-isimli ürünü satın almak istiyorum.`;
    }

    // Construct the WhatsApp link with the message
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Open WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };
  function removeHtmlTags(htmlString) {
    var tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText;
  }
const handlePurchase = () => {

}
// we can redirect to targetLİnk
const handleClick = (url) => {
  window.open(url);
};
  return (
    <div className="relative bg-white rounded-lg   overflow-hidden transition duration-200 ease hover:cursor-pointer">
      <figure className="w-full relative group">
        <div className="h-2/3 relative" onClick={handleSliderOpen}>
          <img src={imageUrl} alt={title} className="object-cover h-48 w-full"  />
          <div className="absolute inset-0 bg-gradient"></div>
          <div className=" absolute bottom-1/2 right-1/2  translate-x-1/2 font-semibold -translate-y-1/2 text-white opacity-50  text-lg transform -rotate-45">Ankara Kartonpiyer</div>
        </div>
      </figure>
      <div className="flex items-center p-1 ">
        <div className="w-4/5 p-2">
          <h3 className="base-heading base-heading--3 base-heading--no-margin text-dark-6000 font-semibold text-base">{title}</h3>
         
        </div>
        <div className=" flex justify-end ">
        {videoLink && (
  <button
    id='video'
    className='bg-white text-button border border-button col-span-2 rounded-lg p-1 text-xs font-semibold hover:shadow-md'
    onClick={() => handleClick(videoLink)}>
    Ürün Videosu
  </button>
)}
         
        {text &&  <button className='bg-white text-button border border-button col-span-2 rounded-lg p-1 text-xs font-semibold hover:shadow-md' onClick={handleDialogOpen}> {t("common.detail")}</button>}

      </div>
      
      </div>
      <div className=' grid grid-rows-2 items-center gap-2 p-1'>
     

        {price === "9999"
                      ? <span  className='text-md text-center font-semibold p-2 my-4'>İnternetten Satışı Yoktur</span>
                      : 
                     
        <div className='grid grid-cols-3 gap-2 p-1'>
        <button
                        onClick={() => handleClick(hepsiburadaLink)}
                        className="grid grid-rows-2 text-center items-center font-semibold border bg-white rounded p-2 hover:shadow-md">
                    <div className="flex justify-center">
                      <img src="https://upload.wikimedia.org/wikipedia/commons/2/20/Hepsiburada_logo_official.svg" alt="N11 Logo" className="h-5" />
                    </div>
                    <div className="flex justify-center">
                    <span>{price} ₺</span>
                    </div>
                        </button>
                        <button
                        onClick={() => handleClick(n11Link)}
                        className="grid grid-rows-2 text-center items-center font-semibold border bg-white rounded p-2 hover:shadow-md">
                    <div className="flex justify-center">
                      <img src="https://upload.wikimedia.org/wikipedia/commons/6/60/N11_logo.svg" alt="N11 Logo" className="h-5" />
                    </div>
                    <div className="flex justify-center">
                      <span>{price} ₺</span>
                    </div>
                        </button>
                        <button
                        onClick={() => handleClick(trendyolLink)}
                        className="grid grid-rows-2 text-center items-center font-semibold border bg-white rounded p-2 hover:shadow-md">
                    <div className="flex justify-center">
                      <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Trendyol_logo.svg" alt="Trendyol Logo" className="h-5" />
                    </div>
                    <div className="flex justify-center">
                      <span>{price} ₺</span>
                    </div>
                        </button>

        </div>
}

          { <button className='bg-button  hover:shadow-md text-white  rounded-lg p-2 py-4   text-base font-semibold flex justify-between'
           onClick={handleWhatsAppClick}>
             <span>{t("common.purchase")}</span>
           <span>{sale_price ? sale_price + " ₺": ""} </span></button>}
        </div>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <p dangerouslySetInnerHTML={{ __html: text }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Kapat</Button>
        </DialogActions>
      </Dialog>
      <SliderModal images={images} isOpen={sliderOpen} onClose={handleSliderOpen} name={title} />
    </div>
  );
};

export default ProductCard;
