import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom'; 
import HomePage from './common/components/HomePage';
import About from './common/components/About';
import { Header } from './common/components/Header';
import Contact from './common/components/Contact';
import { Footer } from './common/components/Footer';
import PageWrapper from './common/components/PageWrapper';
import { Whatsapp } from './common/components/Whatsapp';
import { Products } from './common/components/Products';
import { SubCategories } from './common/components/SubCategories';

function App() {
  return (
    <Router>
      <Header />
      <div className="bg-background">
        <PageWrapper>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/products" element={<Products />} />
            <Route exact path='/sub' element={<SubCategories />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </PageWrapper>
      </div>
      <Footer />
      <Whatsapp/>
    </Router>
  );
}

export default App;
