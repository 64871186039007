import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link,useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Logo from '../ui/Logo';
import { LangSwitcher } from './LangSwitcher';
import Button from '@mui/material/Button';

export const Header = () => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
const isButtonSelected = (path) => {
  return location.pathname === path;
};
  const list = (
    <List>
      <ListItem  component={Link} to="/">
        <ListItemText primary={t('navigation.home')} />
      </ListItem>
      <ListItem  component={Link} to="/about">
        <ListItemText primary={t('navigation.about')} />
      </ListItem>
      <ListItem  component={Link} to="/contact">
        <ListItemText primary={t('navigation.contact')} />
      </ListItem>
    </List>
  );

  return (<>
    {!isMobile && (
      <div className='bg-headerBackground text-white flex justify-between items-center px-8'>
       <Link to='/'>
       <Logo
src={'/ankaraKartonpiyer.png'}
width={250}
height={250}
className={'p-2'}
/>

          </Link>
          <Grid container spacing={2} className={"flex justify-center "}>
  <Grid item>
    <Link to="/">
      <button
        className={`${
          isButtonSelected('/') ? 'border border-white text-white rounded'  : ''
        }  text-white font-semibold  bg-button rounded px-4 py-2 hover:underline focus:outline-none`}
      >
        {t('navigation.home')}
      </button>
    </Link>
  </Grid>
  <Grid item>
    <Link to="/about">
      <button
        className={`${
          isButtonSelected('/about') ? 'border border-white  text-white  rounded': ''
        }  text-white font-semibold px-4 bg-button rounded py-2 hover:underline focus:outline-none`}
      >
        {t('navigation.about')}
      </button>
    </Link>
  </Grid>
  <Grid item>
    <Link to="/contact">
      <button
        className={`${
          isButtonSelected('/contact') ? 'border border-white  text-white rounded' : ''
        }  text-white font-semibold px-4 bg-button rounded py-2 hover:underline focus:outline-none`}
      >
        {t('navigation.contact')}
      </button>
    </Link>
  </Grid>
</Grid>
        <LangSwitcher />
      </div>
    )}
    {isMobile &&  <AppBar position="static" backgroundcolor="#1c1c1c">
      <Toolbar className="flex justify-between border-b bg-headerBackground ">
   

      
          <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Link to='/'>
              <div className='px-2'>
              <Logo
  src={'/logo.png'}
  width={66}
  height={66}
/>

              </div>
            </Link>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)} >
              {list}
              <LangSwitcher />
            </Drawer>
          </>
       
      </Toolbar>
    </AppBar>  }
    </>
  );
};