import React from 'react';
import { useTranslation } from 'react-i18next';
import Banner from './Banner';
import { Categories } from './Categories';
import ProductCard from './ProductCard';

const HomePage = () => {
    const { t } = useTranslation();

  return (
        <><Banner /><Categories /></>
  );
};

export default HomePage;
