import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Carousel from 'react-material-ui-carousel';

const SliderModal = ({ images, isOpen, onClose, name}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth style={{ height: 'h-screen' }}>
      <DialogTitle>
        <Typography variant="h6">{name}</Typography>
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Carousel>
          {images.map((image, index) => (
            <img key={index} 
            src={image.src} 
            alt={`Image ${index + 1}`} 
            style={{ width: '100%', height: 'h-screen', objectFit: 'contain' }} />
          ))}
        </Carousel>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        {/* You can add additional actions or buttons here if needed */}
      </DialogActions>
    </Dialog>
  );
};

export default SliderModal;
