import React , { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Logo from '../ui/Logo';
import { LangSwitcher } from './LangSwitcher';

export const Footer = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);

  const currentYear = new Date().getFullYear();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
    {!isMobile ? <div className='flex justify-between bg-footerBackground text-white mx-auto  px-16 p-4'>
      <div className='flex items-center'>
        <Logo src={"./ankarakartonpiyer.png"} width={100} height={100} />
        
      </div>

      <div className='grid grid-rows-2 gap-4'>
        <div className='flex justify-center space-x-4'>
        <Link to='/'>{t('navigation.home')}</Link>
        <Link to='/about'>{t('navigation.about')}</Link>
        <Link to='/contact'>{t('navigation.contact')}</Link></div>
       
        <div className='ml-4'>
          &copy; {currentYear} Ankara Kartonpiyer
        </div>
      </div>

      <div>
        <LangSwitcher />
      </div>
    </div> 
    : 

    <div className='flex justify-center bg-background mx-auto border-t px-16 p-4'>
   
    <div className='grid grid-rows-2 gap-4'>
      <div className='flex justify-center space-x-4'>
      <Link to='/'>{t('navigation.home')}</Link>
      <Link to='/about'>{t('navigation.about')}</Link>
      <Link to='/contact'>{t('navigation.contact')}</Link></div>
     
      <div className='ml-4'>
        &copy; {currentYear} Ankara Kartonpiyer
      </div>
    </div>

   
  </div>
    }</>
    
  );
};


