import React , {useState, useEffect}from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useTranslation } from 'react-i18next';
import Logo from '../ui/Logo';

const Banner = () => {
    const handleClick = (url) => {
        window.open(url);
    };
const {t} = useTranslation()
const [showCargo, setShowCargo] = useState(true);
useEffect(() => {
    const interval = setInterval(() => {
        setShowCargo(prevState => !prevState);
    }, 2000); // 3000 milliseconds = 3 seconds

    return () => clearInterval(interval);
}, []);

    return (
        <div className="bg-transparent border rounded-lg py-12 mb-4 text-white animate-fade-in-slow">
        <div className="container mx-auto px-4">
      
            <div className='grid grid-flow-row items-center justify-center text-center'>
                <div className='flex justify-center'>
                   
                     <h1 className="text-5xl font-bold mb-4 text-button" >Ankara </h1>
                <h1 className="text-4xl font-bold mb-4 text-black  bg-button px-2 p-1 rounded-lg" > Kartonpiyer </h1></div>
               
                <div className='text-2xl mb-8 p-4 rounded-lg'>
                {showCargo ? (
                    <p className={` animate-magnify-slow`}>
                        {t("common.bannercargo")}
                    </p>
                ) : (
                    <p className=''> {t("common.bannercargo")} </p>
                )}
                </div>
            </div>

    
            <div className='grid grid-cols-1 md:grid-cols-2'>
                <div className='grid grid-flow-row text-center md:text-center'>
                    <div className='text-white text-base mb-2 md:mb-0'>{t("common.followus")}</div>
                    <a href="https://www.instagram.com/ankarakartonpiyer?igsh=MWVuZXhhZWI3NDMxdw%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-800 transition duration-300">
                        <InstagramIcon sx={{ fontSize: 24, color: "red" }} />
                    </a>
                </div>
    
                <div className='grid grid-flow-row text-center md:text-right'>
                    <div className='text-white text-base mb-2 md:mb-0 text-center'>{t("common.marketplace")}</div>
                    <div className='flex gap-2 justify-center md:justify-center'>
                        <button onClick={() => handleClick('https://www.hepsiburada.com/ankarakartonpiyer')} className="flex items-center justify-center border bg-white rounded p-2">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/2/20/Hepsiburada_logo_official.svg" alt="Hepsiburada Logo" className="h-5" />
                        </button>
                        <button onClick={() => handleClick('https://m.n11.com/magaza/ankara-kartonpiyer')} className="flex items-center justify-center border bg-white rounded p-2">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/6/60/N11_logo.svg" alt="N11 Logo" className="h-5" />
                        </button>
                        <button onClick={() => handleClick('https://www.trendyol.com/magaza/ankara-kartonpiyer-m-243572?sst=0')} className="flex items-center justify-center border bg-white rounded p-2">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Trendyol_logo.svg" alt="Trendyol Logo" className="h-5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    );
};

export default Banner;
