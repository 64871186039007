import { LinearProgress, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CategoriesCard from './CategoriesCard';
import { SubCategories } from './SubCategories';

export const Categories = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);

  useEffect(() => {
    const storedCategories = sessionStorage.getItem('categories');
    if (storedCategories) {
      setCategories(JSON.parse(storedCategories));
      setIsLoadingCategories(false);
    } else {
      const fetchCategories = async () => {
        try {
   
          const basicAuth = 'Basic ' + btoa(process.env.REACT_APP_USERNAME + ':' + process.env.REACT_APP_PASSWORD);

          console.log(basicAuth); 
          
          const response = await fetch(`https://ankarakartonpiyer.com.tr/backend/wp-json/wc/v3/products/categories?orderby=slug&parent=0&per_page=25`, {
            headers: {
              'Authorization': basicAuth,
              'Content-Type': 'application/json'
            }
          });

          if (response.ok) {
            const responseData = await response.json();
            const sortedCategories = responseData.sort((a, b) => parseInt(a.slug) - parseInt(b.slug));
            setCategories(sortedCategories);
            sessionStorage.setItem('categories', JSON.stringify(sortedCategories));
          } else {
            throw new Error('Something went wrong on the API server!');
          }  
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingCategories(false);
        }
      };

      fetchCategories();
    }
  }, []);

  return (
    <div className=''>
      {isLoadingCategories ? (
        <LinearProgress/>
      ) : (
        <>
          <h3 className='flex justify-center text-white font-semibold text-3xl p-2 border-b mx-16 '> {t("common.products")}</h3>
          <div className='grid xl:grid-cols-3  gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 sm:px-4 mt-8 p-4 animate-fade-in-slow'>
            {categories.map((item) => (
              item.id !== 15 &&
              <CategoriesCard
                key={item.id}
                catId={item.id}
                name={item.name}
                image={item.image ? item.image.src : ''}
                isLoading={isLoadingCategories} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
