import { LinearProgress, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CategoriesCard from './CategoriesCard';
import ButtonBack from '../ui/ButtonBack';


export const SubCategories = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  useEffect(() => {
    const storedCategories = sessionStorage.getItem('subcategories');

    if (storedCategories) {
      window.scroll(0,0)
      setCategories(JSON.parse(storedCategories));
      setIsLoadingCategories(false);
    } else {
      const getCategories = async () => {
        try {
         
          const basicAuth = 'Basic ' + btoa(process.env.REACT_APP_USERNAME + ':' + process.env.REACT_APP_PASSWORD);

          const response = await fetch(`https://ankarakartonpiyer.com.tr/backend/wp-json/wc/v3/products/categories?orderby=id&parent=22`, {
            headers: {
              'Authorization': basicAuth,
              'Content-Type': 'application/json'
            }
          });

          if (response.ok) {
            const responseData = await response.json();
            // Sort categories by slug
            const sortedCategories = responseData.sort((a, b) => parseInt(a.slug) - parseInt(b.slug));
            setCategories(sortedCategories);
            sessionStorage.setItem('subcategories', JSON.stringify(sortedCategories));
          } else {
            throw new Error('Something went wrong on the API server!');
          }  
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingCategories(false);
        }
      };

      getCategories();
    }
  }, []);



  return (
    <div  className=''>
      {isLoadingCategories && isLoadingProducts ? (
        // Render a skeleton or loading indicator while categories are loading
        <div>   
               <LinearProgress/>
       
        <div className='grid xl:grid-cols-3  gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 sm:px-4 mt-8'>
         
          <Skeleton variant="rectangular"  height={333} animation="wave" />
          <Skeleton variant="rectangular"  height={333} animation="wave" />
          <Skeleton variant="rectangular"  height={333} animation="wave" />
          <Skeleton variant="rectangular"  height={333} animation="wave" />
          <Skeleton variant="rectangular"  height={333} animation="wave" />
          <Skeleton variant="rectangular"  height={333} animation="wave" />
          </div>
          </div>
      ) : (
        <>
              <ButtonBack/>
        <h3 className='flex justify-center text-3xl font-semibold p-2 border-b mx-16 text-white'> {t("common.products")}</h3>
        <div className='grid xl:grid-cols-3  gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 sm:px-4 mt-8 p-4'>


            {categories.map((item) => (
              
              item.id !== 15 &&
              <CategoriesCard
                key={item.id}
                catId={item.id}
                name={item.name}
                image={item.image ? item.image.src : ''}
                isLoading={isLoadingCategories} />
            ))}
          </div></>
      )}
    </div>
  );
};
