// File: PageWrapper.jsx
import React from 'react';

const PageWrapper = ({ children }) => {
  return (
    <div className='container mx-auto max-w-6xl py-8  bg-transparent'>
      <div
        style={{
          paddingTop: 'env(safe-area-inset-top)',
          paddingBottom: 'env(safe-area-inset-bottom)',
          minHeight: '100vh', 
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default PageWrapper;
