import {  Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom'; 
import ButtonBack from '../ui/ButtonBack';
import ProductCard from './ProductCard';



 export const Products = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  // Use useLocation hook to get the current location object
  const location = useLocation();
  // Extract the catId parameter from the query string
  const catId = new URLSearchParams(location.search).get('catId');

  useEffect(() => {
    window.scroll(0,0)
    const storedProducts = sessionStorage.getItem(`products_${catId}`);

    if (storedProducts) {
      setProducts(JSON.parse(storedProducts));
      setIsLoadingProducts(false);
    } else {
      const getProductsData = async () => {
        if(!catId) return
        try {
         
          const basicAuth = 'Basic ' + btoa(process.env.REACT_APP_USERNAME + ':' + process.env.REACT_APP_PASSWORD);
    
          setIsLoadingProducts(true);
    
          const response = await fetch(`https://ankarakartonpiyer.com.tr/backend/wp-json/wc/v3/products?category=${catId}&per_page=50&orderby=id&order=asc`, {
            headers: {
              'Authorization': basicAuth,
              'Content-Type': 'application/json'
            }
          });
    
          if (response.ok) {
            const responseData = await response.json();

            const sortedProducts = responseData.sort((a, b) => parseInt(a.sku) - parseInt(b.sku));
            setProducts(sortedProducts);
            sessionStorage.setItem(`products_${catId}`, JSON.stringify(sortedProducts));
          } else {
            throw new Error('Something went wrong on the API server!');
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingProducts(false);
        } 
      };
      getProductsData();
    }
  }, [catId]);  
console.log(products.sort((a, b) => parseInt(a.sku) - parseInt(b.sku)))
  return (
    <div className='mt-2'>
      <ButtonBack/>
    <h3 className='font-semibold p-2 text-white '> {products.length > 0 && products[0].categories[0].name}</h3>
    <div className='grid xl:grid-cols-3 gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1  sm:px-4 mt-8 animate-fade-in-slow '>

      {isLoadingProducts ? (
        // Render a skeleton or loading indicator while products are loading
        <>
          <Skeleton variant="rectangular" height={333} animation="wave" />
          <Skeleton variant="rectangular" height={333} animation="wave" />
          <Skeleton variant="rectangular" height={333} animation="wave" />
        </>
      ) : (products &&
        products.map((item) => (
          <ProductCard
            key={item.id}
            title={item.name}
            text={item.description}
            imageUrl={item.images ? item.images[0].src : ''}
            price={item.regular_price}
            targetLink={item.purchase_note}
            images={item.images} 
            sale_price={item.sale_price}
            hepsiburadaLink={item.hepsiburada_link}
            n11Link={item.n11_link}
            trendyolLink={item.trendyol_link}
            videoLink= {item.video_link}
            />
        ))
      )}
    </div></div>
  )
        }
