import { LinearProgress, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import SliderModal from './SliderModal';





const CheapFlightCard = ({ title, imageUrl,catId}) => {
    const navigate = useNavigate()
    const handleNavigate = () => {
      if(catId === 22) {
        navigate(`/sub`);

      }
      else
        navigate(`/products?catId=${catId}`);
    }
  return (
    <div
      className="relative bg-white rounded-lg  overflow-hidden transition duration-200 ease hover:shadow-2xl cursor-pointer"
      onClick={handleNavigate}
    >
    <figure className="w-full relative group">
  <div className="h-2/3 relative">
    <img src={imageUrl} alt={title} className="object-cover h-48 w-full" />
    <div className="absolute inset-0 bg-gradient"></div>
    <div className="absolute bottom-1/2 right-1/2  translate-x-1/2 font-semibold -translate-y-1/2 text-white opacity-50  text-lg transform -rotate-45">Ankara Kartonpiyer</div>
  </div>
</figure>
      <div className="flex items-center p-1 ">
        <div className="w-3/5 p-2">
          <span className="text-xs font-semibold">{title}</span>
        </div>
        
      </div>
    </div>
  );
};

export const CategoriesCard = ({ catId,name,isLoading,image }) => {
  const { t } = useTranslation();

  function removeHtmlTags(htmlString) {
    
    var tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText;
}
  

return (
    <>
      {isLoading ? (
        <>
          <LinearProgress />
        </>
      ) : (
        <CheapFlightCard title={name} imageUrl={image} catId={catId} />
      )}
    </>
  );
};

export default CategoriesCard